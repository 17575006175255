import { initializeConnector } from '@web3-react/core';
import { WalletConnect } from '@web3-react/walletconnect';
import { WALLETCONNECT_RPC_URL } from '@/constants/chains';

export const [walletConnect, hooks] = initializeConnector<WalletConnect>(
  actions =>
    new WalletConnect({
      actions,
      options: {
        rpc: WALLETCONNECT_RPC_URL,
        qrcode: true
      }
    })
);
