import React from 'react';
import ReactDOM from 'react-dom/client';
import Web3Provider from '@/components/Web3Provider';
import App from '@/App';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Web3Provider>
      <App />
    </Web3Provider>
  </React.StrictMode>
);
