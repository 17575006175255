import { useState, memo, useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '@/utils/misc';
import { Button, ConnectWallet, Dropdown } from '@/components';
import styles from './header.module.css';

const Wallet = () => {
  const [visible, setVisible] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const { account, connector } = useWeb3React();

  const accountButton = useMemo(
    () => (
      <Button onClick={() => setOpenMenu(visible => !visible)}>
        {account && shortenAddress(account)}
      </Button>
    ),
    [account]
  );

  const logout = useCallback(() => {
    if (connector?.deactivate) {
      void connector.deactivate();
    } else {
      void connector.resetState();
    }
  }, [connector]);

  return (
    <div className={styles.wallet}>
      {account ? (
        <Dropdown
          target={accountButton}
          visible={openMenu}
          onClose={() => setOpenMenu(false)}
        >
          <Dropdown.Menu className={styles.dropdownMenu}>
            <Dropdown.Item>
              <button className={styles.dropdownButton} onClick={logout}>
                {'Sign out'}
              </button>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <>
          <Button onClick={() => setVisible(true)}>Connect Wallet</Button>
          <ConnectWallet visible={visible} setVisible={setVisible} />
        </>
      )}
    </div>
  );
};

export default memo(Wallet);
