import React, { memo, useCallback } from 'react';
import { Button, Modal } from '@/components';
import { hooks as metaMaskHooks, metaMask } from '@/connectors/metaMask';
import { walletConnect } from '@/connectors/walletConnect';
import { ReactComponent as MetaMask } from '@/assets/icons/metamask.svg';
import { ReactComponent as WalletConnect } from '@/assets/icons/walletconnect.svg';
import styles from './wallet.module.css';

export interface IWalletProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const Wallet: React.FC<IWalletProps> = ({ visible, setVisible }) => {
  const { useIsActive, useIsActivating } = metaMaskHooks;
  const isActivating = useIsActivating();
  const isActive = useIsActive();
  const connectMetaMask = useCallback(async () => {
    if (metaMask.provider) {
      try {
        await metaMask.activate();
        setVisible(false);
      } catch (error) {
        console.log((error as Error).message);
      }
    } else {
      window.open('https://metamask.io/download.html');
    }
  }, [setVisible]);

  const connectWallet = useCallback(async () => {
    try {
      await walletConnect.activate();
      setVisible(false);
    } catch (error) {
      console.log((error as Error).message);
    }
  }, [setVisible]);

  return (
    <Modal visible={visible} title={'Connect your wallet'} onClose={setVisible}>
      <div className={styles.wallet}>
        <Button
          className={styles.walletButton}
          onClick={connectMetaMask}
          disabled={isActive || isActivating}
        >
          <MetaMask className={styles.walletIcon} />
          <span className={styles.walletText}>Metamask</span>
        </Button>
        <Button className={styles.walletButton} onClick={connectWallet}>
          <WalletConnect className={styles.walletIcon} />
          <span className={styles.walletText}>Wallet connect</span>
        </Button>
      </div>
    </Modal>
  );
};

export default memo(Wallet);
