import { ReactComponent as LayerZero } from '@/assets/layer-zero.svg';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <span className={styles.text}>Powered by</span>
        <div className={styles.logoWrapper}>
          <LayerZero className={styles.logo} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
