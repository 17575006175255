import { forwardRef, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import Wallet from './Wallet';
import styles from './header.module.css';

const Header = forwardRef<HTMLElement>((props, ref) => {
  return (
    <header className={styles.header} ref={ref}>
      <div className={styles.inner}>
        <NavLink to="/" className={styles.home}>
          <Logo className={styles.icon} />
        </NavLink>
        <Wallet />
      </div>
    </header>
  );
});

Header.displayName = 'Header';

export default memo(Header);
