const ETH = 1;
const GOERLI = 5;
const ARBITRUM = 42161;
const ARB_GOERLI = 421613;
const AVAX = 43114;
const FUJI = 43113;

interface ChainInfo {
  chainId: number;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export const CHAIN_IDS: Record<string, number> = {
  ETH,
  GOERLI,
  ARBITRUM,
  ARB_GOERLI,
  AVAX,
  FUJI
} as const;

export const CHAINS: Record<number, ChainInfo> = {
  [CHAIN_IDS.ETH]: {
    chainId: CHAIN_IDS.ETH,
    chainName: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io']
  },
  [CHAIN_IDS.ARBITRUM]: {
    chainId: CHAIN_IDS.ARBITRUM,
    chainName: 'Arbitrum One',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io']
  },
  [CHAIN_IDS.AVAX]: {
    chainId: CHAIN_IDS.AVAX,
    chainName: 'Avalanche Mainnet C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/']
  },
  [CHAIN_IDS.GOERLI]: {
    chainId: CHAIN_IDS.GOERLI,
    chainName: 'Goerli',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://goerli.etherscan.io']
  },
  [CHAIN_IDS.ARB_GOERLI]: {
    chainId: CHAIN_IDS.ARB_GOERLI,
    chainName: 'Arbitrum Goerli',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc/'],
    blockExplorerUrls: ['https://goerli-rollup-explorer.arbitrum.io']
  },
  [CHAIN_IDS.FUJI]: {
    chainId: CHAIN_IDS.FUJI,
    chainName: 'Avalanche Testnet C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/']
  }
};

export const WALLETCONNECT_RPC_URL = {
  [CHAIN_IDS.ETH]: CHAINS[ETH].rpcUrls,
  [CHAIN_IDS.ARBITRUM]: CHAINS[ARBITRUM].rpcUrls,
  [CHAIN_IDS.AVAX]: CHAINS[AVAX].rpcUrls,
  [CHAIN_IDS.GOERLI]: CHAINS[GOERLI].rpcUrls,
  [CHAIN_IDS.ARB_GOERLI]: CHAINS[ARB_GOERLI].rpcUrls,
  [CHAIN_IDS.FUJI]: CHAINS[FUJI].rpcUrls
};

export const AVAX_NETWORKS = [CHAIN_IDS.AVAX, CHAIN_IDS.FUJI];
export const ETH_NETWORKS = [CHAIN_IDS.ETH, CHAIN_IDS.GOERLI];
export const ARB_NETWORKS = [CHAIN_IDS.ARBITRUM, CHAIN_IDS.ARB_GOERLI];

export const MAINNET_IDS = [ETH, ARBITRUM, AVAX];
export const TESTNET_IDS = [GOERLI, ARB_GOERLI, FUJI];

export const OBX_ENV = import.meta.env.VITE_APP_OBX_ENV as string;
export const isMainnet = OBX_ENV === 'production';

export const TOKEN_AVAX = import.meta.env.VITE_APP_TOKEN_AVAX as string;

export const OFT_AVAX = import.meta.env.VITE_APP_OFT_AVAX as string;
export const OFT_MAINNET = import.meta.env.VITE_APP_OFT_MAINNET as string;
export const OFT_ARBITRUM_MAINNET = import.meta.env
  .VITE_APP_OFT_ARBITRUM_MAINNET as string;

export const ENDPOINT_CHAIN_ID_AVAX = import.meta.env
  .VITE_APP_ENDPOINT_CHAIN_ID_AVAX as string;
export const ENDPOINT_CHAIN_ID_MAINNET = import.meta.env
  .VITE_APP_ENDPOINT_CHAIN_ID_MAINNET as string;
export const ENDPOINT_CHAIN_ID_ARBITRUM_MAINNET = import.meta.env
  .VITE_APP_ENDPOINT_CHAIN_ID_ARBITRUM_MAINNET as string;

export const OFT_ADDRESS: Record<number, string> = {
  [CHAIN_IDS.ETH]: OFT_MAINNET,
  [CHAIN_IDS.ARBITRUM]: OFT_ARBITRUM_MAINNET,
  [CHAIN_IDS.AVAX]: OFT_AVAX
};

export const ENDPOINT_CHAIN_ID: Record<number, string> = {
  [CHAIN_IDS.ETH]: ENDPOINT_CHAIN_ID_MAINNET,
  [CHAIN_IDS.ARBITRUM]: ENDPOINT_CHAIN_ID_ARBITRUM_MAINNET,
  [CHAIN_IDS.AVAX]: ENDPOINT_CHAIN_ID_AVAX
};
