import { ReactNode, useEffect } from 'react';
import { Web3ReactHooks, Web3ReactProvider } from '@web3-react/core';
import { MetaMask } from '@web3-react/metamask';
import { WalletConnect } from '@web3-react/walletconnect';
import { hooks as metaMaskHooks, metaMask } from '@/connectors/metaMask';
import {
  hooks as walletConnectHooks,
  walletConnect
} from '@/connectors/walletConnect';

export default function Web3Provider({ children }: { children: ReactNode }) {
  useEffect(() => {
    void metaMask.connectEagerly().catch(() => {
      console.debug('Failed to connect eagerly to metamask');
    });
  }, []);

  useEffect(() => {
    walletConnect.connectEagerly().catch(error => {
      console.debug('Failed to connect eagerly to walletconnect', error);
    });
  }, []);

  const connectors: [MetaMask | WalletConnect, Web3ReactHooks][] = [
    [metaMask, metaMaskHooks],
    [walletConnect, walletConnectHooks]
  ];

  return (
    <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
  );
}
